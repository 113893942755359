<template>
  <div>
    <section v-if="content" v-html="renderMd(content)" class="mb-4"></section>
    <section v-if="summary || tags || subjectsString" class="summary">
      <h3>Sumár pre učiteľa</h3>
      <b-row>
        <b-col md="6">
          <div v-if="subjectsString" class="d-flex mb-sm-5 mb-3">
            <div class="z-icon-wrapper -middle">
              <svg-school-hat-icon />
            </div>
            <div>
              <h4>Predmety</h4>
              <p>{{ subjectsString }}</p>
            </div>
          </div>

          <div v-if="tags" class="d-flex mb-sm-5 mb-3">
            <div class="z-icon-wrapper -middle">
              <svg-chatbubble-icon />
            </div>
            <div>
              <h4>Kľúčové slová</h4>
              <p class="content" v-html="tags"></p>
            </div>
          </div>
        </b-col>
        <b-col md="6" v-if="summary">
          <div class="d-flex">
            <div class="z-icon-wrapper -middle -icongroup">
              <svg-crosshairs-icon class="-crosshairs" />
              <svg-star-icon />
            </div>
            <div>
              <h4>Ciele</h4>
              <p class="content" v-html="renderMd(summary)"></p>
            </div>
          </div>
        </b-col>
      </b-row>
    </section>

    <LessonContentSection
      v-if="links"
      title="Užitočné linky"
      :content="renderMd(links)"
    >
      <template v-slot:icon>
        <div class="z-icon-wrapper -middle w-background-primary">
          <svg-link-icon />
        </div>
      </template>
    </LessonContentSection>

    <LessonContentSection
      v-if="resources"
      title="Zdroje"
      :content="renderMd(resources)"
    >
      <template v-slot:icon>
        <div class="z-icon-wrapper -middle w-background-primary">
          <svg-document-icon />
        </div>
      </template>
    </LessonContentSection>

    <section v-if="partners">
      <LessonContentDetailPartners :partners="partners" />
    </section>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";

export default {
  components: {
    LessonContentSection: () =>
      import("/components/Lesson/LessonContentSection.vue"),
    LessonContentDetailPartners: () =>
      import("/components/Lesson/Content/LessonContentDetailPartners.vue"),
    "svg-school-hat-icon": () => import("/assets/icons/schoolHat.svg?inline"),
    "svg-crosshairs-icon": () => import("/assets/icons/crosshairs.svg?inline"),
    "svg-star-icon": () => import("/assets/icons/star.svg?inline"),
    "svg-chatbubble-icon": () => import("/assets/icons/chatBubble.svg?inline"),
    "svg-link-icon": () => import("/assets/icons/link.svg?inline"),
    "svg-document-icon": () => import("/assets/icons/document.svg?inline"),
  },

  props: {
    content: {
      type: String,
    },
    summary: {
      type: String,
    },
    tags: {
      type: String,
    },
    subjects: {
      type: Array,
    },
    links: {
      type: String,
    },
    resources: {
      type: String,
    },
    partners: {
      type: Array,
    },
  },

  data() {
    return {
      md: null,
    };
  },

  computed: {
    subjectsString: function () {
      if (!this.subjects || !this.subjects.length) {
        return "";
      }

      return this.subjects.map((s) => s.name.toLowerCase()).join(", ");
    },
  },

  async created() {
    this.md = new MarkdownIt({
      html: true,
    });
  },

  methods: {
    renderMd(input) {
      return this.md.render(input);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  background-color: rgba(var(--a-color-blue-light-rgb), 0.5);
  border: 1px solid var(--a-color-blue-light);
  border-radius: 0.675rem;
}

.content,
::v-deep .content {
  white-space: pre-wrap;
  word-break: break-word;

  a {
    margin: 0.5rem 0;
    display: contents;
  }
}

@media only screen and (max-width: 575px) {
  section,
  ::v-deep section {
    padding: 2rem 0;
    position: relative;

    .z-icon-wrapper {
      margin-right: 1rem;
    }
  }

  .summary {
    margin: 0 -1rem;
    padding: 2rem 1rem;
  }

  ::v-deep section.-content {
    .z-icon-wrapper {
      position: absolute;
      top: 2rem;
    }

    h3 {
      padding-left: 3rem;
      padding-top: 0.35rem;
    }
  }
}
</style>
